import React, {useState, useEffect} from 'react'
import {
	Container,
	Grid,
	Loader,
	Header
} from 'semantic-ui-react'
import { Link, useStaticQuery, graphql} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import SubCategoryListImage from '../components/SubCategoryList/SubCategoryImage'
import * as styles from './styles/pages.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Category = ({location}) => {
	const [shown, setShown] = useState(null)

	useEffect(()=>{
		handler()
		let timeOut1 = null
		const resizeTimerFunc = () => {
			clearTimeout(timeOut1);
			const temp = setTimeout(handler, resizeTimer);

			timeOut1 = temp
		}
		window.addEventListener('resize', resizeTimerFunc);
		return () => {
			clearTimeout(timeOut1);
			window.removeEventListener('resize', resizeTimerFunc);
		}
	}, [])



	const handler = ()=> {
		if (window.innerWidth > tabletMaxWidth ) {
			setShown(5)
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			setShown(3)
		} else {  //mobile
			setShown(2)
		}
	}
	const MenuTemp = useStaticQuery(graphql`
		query MenuMobileQuery {
			example {
				result {
					name
					url
					children {
						squareImageHref
						name
						url
					}
				}
			}
		}
	 `)
	const MakeMenu = (item, index, obj) => {
		return (
			<Grid.Row
				key={item.name}

			>
				<Grid.Column width={16} className={styles.centerText}>
					{item.url ? (
						<Link
							to={`/${item.url}`}
							className={styles.superHeader}
						>
							{item.name} Categories
						</Link>
					) : (
						<Header className={styles.superHeader}>
							{item.name} Categories
						</Header>
					)}


				</Grid.Column>

				{ item.children.length > 0 && (
					<Grid.Column width={16}>

						<SubCategoryListImage
						  relatedcategorylist={item.children}
						  shown={shown}
						/>
					</Grid.Column>
					)
				}
			</Grid.Row>
		)
	}
	const menu = MenuTemp.example.result
	const results = menu.map(MakeMenu)
	//not sure if I really need the Loader since this is all static data, is not more than 0 then there's something wrong with the fetch in gatsby-node.js and the Loader here would be seriously misleading
	return (
		<Layout location={location}>
			<Seo title="Category List" />
			<Container className={styles.container}>
				<Grid divided='vertically'>
					{ menu.length ? (
						<React.Fragment >
							{results}
						</React.Fragment>
					) : (
						<Loader active inline="centered" />
					)}
				</Grid>
			</Container>
		</Layout>
	)
}
export default Category