import React, {useState, useEffect} from 'react'
import {Image, Card} from 'semantic-ui-react'
import {Link} from 'gatsby'
//import * as styles from './styles/subCategoryList.module.css'


const mapCategoriesToItems = relatedcategorylist => {
	return relatedcategorylist.map(({name, squareImageHref, url }) => {
		return {
			as: Link,
			to: `/${url}`,
			key: name,
			image: (
				<Image fluid
					src={squareImageHref}
					alt={name}
					title={name}
				/>
			),
			//header: <Card.Header className={styles.categorySquare}>{name}</Card.Header>,
			//className:styles.card
		}
	})
}

const SubCategoryImageComponent = ({relatedcategorylist, shown}) => {
	const [list, setList] = useState([])
	useEffect(()=>
	{
		//filters categorylist so that it won't show those without a url and a square image
		const filteredCategoryList = relatedcategorylist.filter((category)=>category.url)
		const completelyfilteredlist = filteredCategoryList.filter((category)=>category.squareImageHref)
		// 20201101 - Removed slice, causing some categories to be missing
		//setList(completelyfilteredlist.slice(0, 10))
		setList(completelyfilteredlist)


		/* only added relatedcategorylist to remove the
		 * react-hooks/exhaustive deps warning though it shouldn't
		 * break without it since relatedcategorylist is a prop from
		 * build time from gatsby node
		 */
	}, [relatedcategorylist])

	//how many will be shown
	let tempShown = shown ? shown : 5

	const length = (list.length > 5 && !(shown)) ? list.length : tempShown
	return (

		<Card.Group
			items={mapCategoriesToItems(list)}
			itemsPerRow={length}

		/>
	)
}

export default SubCategoryImageComponent
